import axios from 'axios';
import EnvironmentService from './environment.service';
import LogService from './LogService';

const getDataProtection = async ({ angebotTyp, partner }: { angebotTyp: string; partner: string | null }) => {
  const url = `${EnvironmentService.getApimBaseUrl()}/api/v1/privacy/${partner}/${angebotTyp}`;
  try {
    const response = await axios.get(url, {
      headers: {
        [EnvironmentService.getApiSubscriptionKey()]: EnvironmentService.getApiSubscriptionValue(),
        'Cache-Control': 'no-cache',
      },
      responseType: 'blob',
    });
    const pdf = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = pdf;
    link.setAttribute('download', 'terms.pdf');
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    LogService.error(error);
  }
};

// eslint-disable-next-line import/prefer-default-export
export const DocumentService = {
  getDataProtection,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    DIGITAL_SIGNATURE_APIM_BASE_URL: string;
    APIM_SUBSCRIPTION_KEY: string;
    APIM_SUBSCRIPTION_VALUE: string;
  }
}

const { NODE_ENV } = process.env;
const { REACT_APP_DIGITAL_SIGNATURE_APIM_BASE_URL }: string | any = process.env;
const { REACT_APP_APIM_SUBSCRIPTION_KEY }: string | any = process.env;
const { REACT_APP_APIM_SUBSCRIPTION_VALUE }: string | any = process.env;

const getApimBaseUrl = (): string =>
  NODE_ENV === 'production' ? window.DIGITAL_SIGNATURE_APIM_BASE_URL : REACT_APP_DIGITAL_SIGNATURE_APIM_BASE_URL;

const getApiSubscriptionKey = (): string =>
  NODE_ENV === 'production' ? window.APIM_SUBSCRIPTION_KEY : REACT_APP_APIM_SUBSCRIPTION_KEY;

const getApiSubscriptionValue = (): string =>
  NODE_ENV === 'production' ? window.APIM_SUBSCRIPTION_VALUE : REACT_APP_APIM_SUBSCRIPTION_VALUE;

const EnvironmentService = {
  getApimBaseUrl,
  getApiSubscriptionKey,
  getApiSubscriptionValue,
};

export default EnvironmentService;

import React, { ReactNode } from 'react';
import { Row, Col } from 'react-bootstrap';

type DataConsentViewProps = {
  children: ReactNode;
};

function DataConsentTemplate({ children }: DataConsentViewProps) {
  return (
    <Row className="data_consent_view_body">
      {React.Children.map(children, (child) => (
        <Col className="text-left">{child}</Col>
      ))}
    </Row>
  );
}

export default DataConsentTemplate;

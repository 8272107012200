/* eslint-disable no-return-await */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { DocumentService } from '../services/document.service';
import LogService from '../services/LogService';
import DataConsentTemplate from './DataConsentTemplate';
import './Home.scss';

function Home() {
  const { search }: { search: string } = useLocation();
  const [isParamsAvailable, setIsParamsAvailable] = useState(false);
  const searchParams: URLSearchParams = new URLSearchParams(search);

  const angebotTyp = searchParams.get('angebotTyp');
  const partner = searchParams.get('partner');

  const getAngebotTyp = async () =>
    angebotTyp === 'gewerblich'
      ? await DocumentService.getDataProtection({ angebotTyp: 'b2b', partner })
      : await DocumentService.getDataProtection({ angebotTyp: 'b2c', partner });

  useEffect(() => {
    if (angebotTyp && partner && !isParamsAvailable) setIsParamsAvailable(true);
  }, [angebotTyp, partner, setIsParamsAvailable, isParamsAvailable]);

  LogService.log('isParamsAvailable', isParamsAvailable);

  return (
    <Container className="home">
      <h1 className="home_title">Datenschutzerklärung</h1>
      <Container className="home_content">
        <DataConsentTemplate>
          <span className="data_consent_title">1. Datenschutz auf einen Blick Allgemeine Hinweise</span>
          <span className="data_consent_description">
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
            passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
            identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter
            diesem Text aufgeführten Datenschutzerklärung.
          </span>
          <h2 className="data_consent_title">
            Datenerfassung auf dieser Website Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </h2>
          <span className="data_consent_description">
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie
            dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
          </span>
          <h2 className="data_consent_title">Wie erfassen wir Ihre Daten?</h2>
          <span className="data_consent_description">
            <p>
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
              Daten handeln, die Sie in ein Kontaktformular eingeben.
            </p>
            <p>{``}</p>
            <p>
              Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere
              IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
              Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
              betreten.
            </p>
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title">Wofür nutzen wir Ihre Daten?</h2>
          <span className="data_consent_description">
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
            Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
          </span>
          <h2 className="data_consent_title">Welche Rechte haben Sie bezüglich Ihrer Daten?</h2>
          <span className="data_consent_description">
            <p>
              Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
              gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
              Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können
              Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
              bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
              Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
            </p>
            <p>{``}</p>
            <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</p>
          </span>
          <h2 className="data_consent_title">Analyse-Tools und Tools von Drittanbietern</h2>
          <span>
            <p>
              Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem
              mit sogenannten Analyseprogrammen.
            </p>
            <p>{``}</p>
            <p>
              Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
            </p>
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">2. Hosting</h2>
          <h2 className="data_consent_title text-left">Hosting mit Microsoft Azure</h2>
          <span className="data_consent_description">
            <p>
              Wir hosten unsere Website bei Microsoft Azure. Anbieter ist die Microsoft Corporation, One Microsoft Way,
              98052-6399 Redmond WA, USA (nachfolgend: Microsoft Azure).
            </p>
            <p>{``}</p>
            <p>
              Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten auf den Servern von Microsoft Azure
              verarbeitet. Hierbei können auch personenbezogene Daten an den Anbieter von Microsoft Azure in die USA
              übermittelt werden. Die Datenübertragung in die USA wird auf die EU-Standardvertragsklauseln gestützt.
              Details finden Sie hier:{' '}
              <a href="https://azure.microsoft.com/de-de/overview/trusted-cloud/privacy/.">
                https://azure.microsoft.com/de-de/overview/trusted-cloud/privacy/.
              </a>
            </p>
            <p>{``}</p>
            <p>
              Weitere Informationen entnehmen Sie der Datenschutzerklärung von Microsoft Azure:{' '}
              <a href=" https://docs.microsoft.com/de-de/azure/compliance/.">
                https://docs.microsoft.com/de-de/azure/compliance/.
              </a>
            </p>
            <p>{``}</p>
            <p>
              Die Verwendung von Microsoft Azure erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DS-GVO. Wir haben ein
              berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art.
              6 Abs. 1 lit. a DS-GVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
          </span>
          <h2 className="data_consent_title text-left">Auftragsverarbeitung</h2>
          <span className="data_consent_description">
            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen. Hierbei
            handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die
            personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DS-GVO
            verarbeitet.
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">3. Allgemeine Hinweise und Pflichtinformationen Datenschutz</h2>
          <h2 className="data_consent_title text-left">Datenschutz</h2>
          <span className="data_consent_description text-left pb-5">
            <p>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
              personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
              Datenschutzerklärung.
            </p>
            <p>{``}</p>
            <p>
              Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene
              Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
              Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
              und zu welchem Zweck das geschieht.
            </p>
            <p>{``}</p>
            <p>
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
              Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
              möglich.
            </p>
          </span>
          <h2 className="data_consent_title text-left">Hinweis zur verantwortlichen Stelle</h2>
          <span className="data_consent_description text-left pb-5">
            <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
            <div className="data_consent_address">
              <span>ALD AutoLeasing D GmbH</span>
              <span>Zentrale Hamburg</span>
              <span>Nedderfeld 95</span>
              <span>D-22529 Hamburg</span>
            </div>
          </span>
          <div className="data_consent_contact">
            <span>Telefon: +49 40 47104-0</span>
            <span className="text-left">E-Mail: info@aldautomotive.com</span>
          </div>
          <span className="data_consent_description text-left">
            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
            über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
            entscheidet.
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">Speicherdauer</h2>
          <span className="data_consent_description text-left">
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre
            personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes
            Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
            gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen
            Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
            Löschung nach Fortfall dieser Gründe.
          </span>
          <h2 className="data_consent_title text-left">Datenschutzbeauftragter</h2>
          <span className="data_consent_description text-left">
            Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.
          </span>
          <div className="data_consent_contact">
            <span>Detlef Weidlich</span>
            <span>ALD AutoLeasing D GmbH</span>
            <span>Zentrale Hamburg</span>
            <span>Nedderfeld 95</span>
            <span>22529 Hamburg</span>
          </div>
          <div className="data_consent_contact">
            <span>Telefon: +49 40 47104-0</span>
            <span className="text-left">E-Mail: datenschutz@aldautomotive.com</span>
          </div>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">
            Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
          </h2>
          <span className="data_consent_description text-left">
            Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich
            nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese
            Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit
            der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu
            verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener
            hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B.
            Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und
            dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
          </span>
        </DataConsentTemplate>
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h2>
          <span className="data_consent_description text-left">
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
            bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DS-GVO)
          </h2>
          <span className="data_consent_description text-left">
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DS-GVO ERFOLGT, HABEN SIE JEDERZEIT
            DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
            PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
            PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
            DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN
            NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
            NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DS-GVO).{' '}
          </span>
          <span className="data_consent_description text-left">
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
            JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
            WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
            STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
            DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DS-GVO).
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">Beschwerderecht bei der zuständigen Aufsichtsbehörde</h2>
          <span className="data_consent_description text-left">
            Im Falle von Verstößen gegen die DS-GVO steht den Betroffenen ein Beschwerderecht bei einer
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
            verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
          </span>
          <h2 className="data_consent_title text-left">Recht auf Datenübertragbarkeit</h2>
          <span className="data_consent_description text-left">
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
            automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
            verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </span>
          <h2 className="data_consent_title text-left">SSL- bzw. TLS-Verschlüsselung</h2>
          <span className="data_consent_description text-left">
            <p>
              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
              Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
              TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
              von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            </p>
            <p>{''}</p>
            <p>
              Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
              von Dritten mitgelesen werden.
            </p>
          </span>
          <h2 className="data_consent_title text-left">Verschlüsselter Zahlungsverkehr auf dieser Website</h2>
          <span className="data_consent_description text-left">
            <p>
              Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine Verpflichtung, uns Ihre Zahlungsdaten (z.
              B. Kontonummer bei Einzugsermächtigung) zu übermitteln, werden diese Daten zur Zahlungsabwicklung
              benötigt.
            </p>
            <p>{''}</p>
            <p>
              Der Zahlungsverkehr über die gängigen Zahlungsmittel erfolgt ausschließlich über eine verschlüsselte SSL-
              bzw. TLS-Verbindung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
              von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            </p>
            <p>{''}</p>
            <p>
              Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an uns übermitteln, nicht von Dritten
              mitgelesen werden.
            </p>
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">Auskunft, Löschung und Berichtigung</h2>
          <span className="data_consent_description text-left">
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
            über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
            Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
          </span>
          <h2 className="data_consent_title text-left">Recht auf Einschränkung der Verarbeitung</h2>
          <span className="data_consent_description text-left">
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu
            können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
            Fällen:
          </span>
          <ul className="data_consent_description">
            <li className="text-left">
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in
              der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung
              der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
            <li className="text-left">
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der
              Löschung die Einschränkung der Datenverarbeitung verlangen.
            </li>
            <li className="text-left">
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder
              Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung
              der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
            <li className="text-left">
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DS-GVO eingelegt haben, muss eine Abwägung zwischen Ihren
              und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
              haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <span className="data_consent_description">
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer
            Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet
            werden.
          </span>
          <h2 className="data_consent_title text-left">Widerspruch gegen Werbe-E-Mails</h2>
          <span className="data_consent_description text-left">
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht
            ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
            Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
            Werbeinformationen, etwa durch Spam-E-Mails, vor.
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">4. Datenerfassung auf dieser Website Cookies</h2>
          <span className="data_consent_description text-left">
            <p>
              Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf
              Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung
              (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies
              werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
              gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
            </p>
            <p>{''}</p>
            <p>
              Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere
              Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter
              Dienstleistungen des Drittunternehmens (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
            </p>
            <p>{''}</p>
            <p>
              Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
              Websitefunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von
              Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
            </p>
            <p>{''}</p>
            <p>
              Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur
              Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die
              Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies zur Messung des Webpublikums)
              erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DS-GVO gespeichert, sofern keine andere
              Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
              Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung
              zur Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies
              ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DS-GVO); die Einwilligung ist
              jederzeit widerrufbar.
            </p>
            <p>{''}</p>
            <p>
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies
              nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
              das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von
              Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </p>
            <p>{''}</p>
            <p>
              Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber im
              Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.
            </p>
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">Server-Log-Dateien</h2>
          <span className="data_consent_description text-left">
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien,
            die Ihr Browser automatisch an uns übermittelt. Dies sind:
          </span>
          <ul className="data_consent_description text-left">
            <li className="text-left">Browsertyp und Browserversion</li>
            <li className="text-left">verwendetes Betriebssystem</li>
            <li className="text-left">Referrer URL</li>
            <li className="text-left">Hostname des zugreifenden Rechners</li>
            <li className="text-left">Uhrzeit der Serveranfrage</li>
            <li className="text-left">IP-Adresse</li>
          </ul>
          <span className="data_consent_description text-left">
            <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
            <p>{''}</p>
            <p>
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DS-GVO. Der Websitebetreiber hat
              ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website –
              hierzu müssen die Server-Log-Files erfasst werden.
            </p>
          </span>
          <h2 className="data_consent_title text-left">Kontaktformulare</h2>
          <span className="data_consent_description text-left">
            <p>
              Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
              inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
              Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
            </p>
            <p>{''}</p>
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DS-GVO, sofern Ihre Anfrage
              mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
              erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an
              der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DS-GVO) oder auf Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DS-GVO) sofern diese abgefragt wurde.
            </p>
            <p>{''}</p>
            <p>
              Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
              auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
              (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
              Aufbewahrungsfristen – bleiben unberührt.
            </p>
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">Anfrage per E-Mail oder Telefon</h2>
          <span className="data_consent_description text-left">
            <p>
              Wenn Sie uns per E-Mail oder Telefon kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und
              verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
            </p>
            <p>{''}</p>
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DS-GVO, sofern Ihre Anfrage
              mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
              erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an
              der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DS-GVO) oder auf Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DS-GVO) sofern diese abgefragt wurde.
            </p>
            <p>{''}</p>
            <p>
              Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
              auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
              (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
              insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
            </p>
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">Registrierung auf dieser Website</h2>
          <span className="data_consent_description text-left">
            <p>
              Sie können sich auf dieser Website registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die
              dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes,
              für den Sie sich registriert haben. Die bei der Registrierung abgefragten Pflichtangaben müssen
              vollständig angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.
            </p>
            <p>{''}</p>
            <p>
              Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die
              bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
            </p>
            <p>{''}</p>
            <p>
              Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt zum Zwecke der Durchführung des
              durch die Registrierung begründeten Nutzungsverhältnisses und ggf. zur Anbahnung weiterer Verträge (Art. 6
              Abs. 1 lit. b DS-GVO).
            </p>
            <p>{''}</p>
            <p>
              Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf dieser Website
              registriert sind und werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
            </p>
          </span>
          <h2 className="data_consent_title text-left">5. E-Mail-Versand Mailjet</h2>
          <span className="data_consent_description text-left">
            <p>
              Diese Website nutzt Mailjet für den Versand von E-Mails. Anbieter ist die Mailgun Technologies Inc., 112 E
              Pecan Sr. #1135, San Antonio, Texas 78205, USA (nachfolgend: Mailjet).
            </p>
            <p>{''}</p>
            <p>
              Mailjet ist ein Dienst, mit dem u. a. der Versand von E-Mails organisiert und analysiert werden kann. Die
              von Ihnen zum Zwecke des E-Mail-Bezuges eingegebenen Daten werden auf den Servern von Mailjet gespeichert.
            </p>
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">Datenanalyse durch Mailjet</h2>
          <span className="data_consent_description text-left">
            <p>
              Mit Hilfe von Mailjet ist es uns möglich, unsere E-Mail-Kampagnen zu analysieren. So können wir z. B.
              sehen, ob eine E-Mail-Nachricht geöffnet wurde und ggf. welche Links angeklickt wurden. Auf diese Weise
              können wir u. a. feststellen, welche Links besonders oft angeklickt werden.
            </p>
            <p>{''}</p>
            <p>
              Außerdem können wir erkennen, ob nach dem Öffnen und Anklicken bestimmte vorher definierte Aktionen
              durchgeführt wurden (Conversion-Rate). Wir können so z. B. feststellen, ob Sie nach dem Anklicken der
              E-Mail einen Kauf getätigt haben.
            </p>
            <p>{''}</p>
            <p>
              Mailjet ermöglicht es uns auch, alle E-Mail-Empfänger anhand verschiedener Kategorien zu unterteilen
              („clustern“). Dabei lassen sich die E-Mail-Empfänger z. B. nach Alter, Geschlecht oder Wohnort
              unterteilen. Auf diese Weise lassen sich unsere E-Mails besser an die jeweiligen Zielgruppen anpassen.
            </p>
            <p>{''}</p>
            <p>
              Ausführliche Informationen zu den Funktionen von Mailjet können Sie unter folgendem Link einsehen:{' '}
              <a href="https://www.mailjet.de/funktion/.">https://www.mailjet.de/funktion/.</a>
            </p>
            <p>{''}</p>
            <p>
              Die Datenschutzerklärung von Mailjet finden Sie unter:{' '}
              <a href="https://www.mailjet.de/sicherheit-datenschutz/.">
                https://www.mailjet.de/sicherheit-datenschutz/.
              </a>
            </p>
          </span>
          <h2 className="data_consent_title text-left">Rechtsgrundlage</h2>
          <span className="data_consent_description text-left">
            <p>
              Die Datenverarbeitung erfolgt mit Blick auf die Vertragserfüllung (Art. 6 Abs. 1 lit. b DS-GVO) sowie zur
              Gewährleistung einer Kundenkommunikation (berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DS-GVO). Sofern
              eine Einwilligung eingeholt wurde, erfolgt die E-Mail-Kommunikation auf Grundlage dieser Einwilligung
              (Art. 6 Abs. 1 lit. DS-GVO); die Einwilligung ist jederzeit widerrufbar. Die Rechtmäßigkeit der bereits
              erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
            </p>
            <p>{''}</p>
            <p>
              Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier: <a href="https://www.mailjet.de/av-vertrag/.">https://www.mailjet.de/av-vertrag/.</a>
            </p>
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">Speicherdauer</h2>
          <span className="data_consent_description text-left">
            <p>
              Die von Ihnen zum Zwecke des E-Mail-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
              Austragung bei uns bzw. dem E-Mail-Diensteanbieter gespeichert und anschließend gelöscht.
            </p>
            <p>{''}</p>
            <p>Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon unberührt.</p>
            <p>{''}</p>
            <p>
              Nach Ihrer Austragung wird Ihre E-Mail-Adresse bei uns bzw. dem E-Mail-Diensteanbieter ggf. in einer
              Blacklist gespeichert, sofern dies zur Verhinderung künftiger Mailings erforderlich ist. Die Daten aus der
              Blacklist werden nur für diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies dient
              sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand
              von E-Mails (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DS-GVO). Die Speicherung in der
              Blacklist ist zeitlich nicht befristet.{' '}
              <b>
                Sie können der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse
                überwiegen.
              </b>
            </p>
          </span>
          <h2 className="data_consent_title text-left">Auftragsverarbeitung</h2>
          <span className="data_consent_description text-left">
            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen. Hierbei
            handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die
            personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DS-GVO
            verarbeitet.
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">6. Plugins und Tools</h2>
          <h2 className="data_consent_title text-left">Google Web Fonts</h2>
          <span className="data_consent_description text-left">
            <p>
              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
              bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
              Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
            </p>
            <p>{''}</p>
            <p>
              Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen.
              Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse diese Website aufgerufen wurde. Die
              Nutzung von Google WebFonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DS-GVO. Der Websitebetreiber
              hat ein berechtigtes Interesse an der einheitlichen Darstellung des Schriftbildes auf seiner Website.
              Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine Einwilligung zur Speicherung von
              Cookies), erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DS-GVO; die
              Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>{''}</p>
            <p>Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.</p>
            <p>{''}</p>
            <p>
              Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
              <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der
              Datenschutzerklärung von Google:{' '}
              <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy?hl=de</a>.
            </p>
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">
            7. eCommerce und Auskunfteien Verarbeiten von Daten (Kunden- und Vertragsdaten)
          </h2>
          <span className="data_consent_description text-left">
            <p>
              Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche
              Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten). Dies erfolgt auf
              Grundlage von Art. 6 Abs. 1 lit. b DS-GVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags
              oder vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über die Inanspruchnahme dieser Website
              (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die
              Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.
            </p>
            <p>{''}</p>
            <p>
              Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung
              gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
            </p>
          </span>
          <h2 className="data_consent_title text-left">
            Datenübermittlung bei Vertragsschluss, an Händler und zum Warenversand
          </h2>
          <span className="data_consent_description text-left">
            Wenn Sie Waren bei uns bestellen, geben wir Ihre personenbezogenen Daten an das zur Lieferung betraute
            Unternehmen sowie an den mit der Zahlungsabwicklung beauftragten Zahlungsdienstleister weiter. Es werden nur
            solche Daten herausgegeben, die der jeweilige Dienstleister zur Erfüllung seiner Aufgabe benötigt.
            Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. b DS-GVO, der die Verarbeitung von Daten zur Erfüllung eines
            Vertrags oder vorvertraglicher Maßnahmen gestattet. Sofern Sie eine entsprechende Einwilligung nach Art. 6
            Abs. 1 lit. a DS-GVO erteilt haben, werden wir Ihre E-Mail-Adresse an das mit der Lieferung betraute
            Unternehmen übergeben, damit dieses Sie per E-Mail über den Status Ihrer Bestellung informieren kann; Sie
            können diese Einwilligung jederzeit widerrufen.
          </span>
          <h2 className="data_consent_title text-left">
            Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale Inhalte
          </h2>
          <span className="data_consent_description text-left">
            <p>
              Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung
              notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte Kreditinstitut.
            </p>
            <p>{''}</p>
            <p>
              Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung
              ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche Einwilligung, etwa
              zu Zwecken der Werbung, erfolgt nur mit Ihrer Einwilligung.
            </p>
            <p>{''}</p>
            <p>
              Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DS-GVO, der die Verarbeitung von Daten zur
              Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
            </p>
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">Bonitätsprüfungen</h2>
          <span className="data_consent_description text-left">
            <p>
              Unter gewissen Umständen besteht auf unserer Seite ein berechtigtes Interesse mit Hilfe unserer Website
              eine Bonitätsprüfung durchzuführen. Hierbei übermitteln wir Ihre eingegebenen Daten (z. B. Name und
              Adresse) an Auskunfteien. Auf Grundlage dieser Daten wird etwa die Wahrscheinlichkeit eines
              Zahlungsausfalls ermittelt. Bei einem überhöhten Zahlungsausfallrisiko könnten wir einen Vertragsschluss
              verweigern.
            </p>
            <p>{''}</p>
            <p>
              Die Bonitätsprüfung erfolgt insbesondere mit Blick auf eine Vertragserfüllung (Art. 6 Abs. 1 lit. b
              DS-GVO) sowie zur Vermeidung von Zahlungsausfällen (berechtigtes Interesse nach Art. 6 Abs. 1 lit. f
              DS-GVO). Sofern eine Einwilligung eingeholt wurde, erfolgt die Bonitätsprüfung auf Grundlage dieser
              Einwilligung (Art. 6 Abs. 1 lit. DS-GVO); die Einwilligung ist jederzeit widerrufbar.
            </p>
          </span>
          <h2 className="data_consent_title text-left">Datenübermittlungen an Auskunfteien</h2>
          <span className="data_consent_description text-left">
            <p>
              Wir übermitteln im Rahmen von Voranfragen und bei Vertragsschluss Daten über die Beantragung, Aufnahme und
              vertragsgemäße Abwicklung sowie ggf. während bzw. nach Beendigung der Vertragsbeziehung Daten über nicht
              vertragsgemäßes oder betrügerisches Verhalten und fällige Forderungen an Auskunfteien, etwa die SCHUFA
              Holding AG, Kormoranweg 5, 65201 Wiesbaden oder den Verband der Vereine Creditreform e. V. (Crefo),
              Hammfelddamm 13, 41460 Neuss. Diese Auskunfteien verarbeiten die jeweiligen Daten zur Ermittlung eines
              Scorewertes sowie zur Übermittlung an Vertragspartner, die aufgrund von Leistungen oder Lieferungen
              finanzielle Ausfallrisiken tragen. Genauere Informationen hierzu finden sich in den Informationsblättern
              der Auskunfteien.
            </p>
            <p>{''}</p>
            <p>
              Zur vereinfachten Identifikation sowie Adressverifikation gleichen wir ebenfalls etwa Namen, Anschriften
              und Geburtsdaten mit den genannten Auskunfteien ab.
            </p>
          </span>
        </DataConsentTemplate>
        <hr />
        <DataConsentTemplate>
          <h2 className="data_consent_title text-left">IDNow und WebID</h2>
          <span className="data_consent_description text-left">
            <p>
              Wir bieten auf unseren Webangeboten die Möglichkeit an, eine Online-Identifikation durchzuführen, die
              durch den Dienstleister IDnow GmbH, Auenstraße 100, 80469 München (nachfolgend: „IDnow“) oder WebID
              Solutions GmbH, Friedrichstraße 88, 10117 Berlin, Deutschland (nachfolgend: „WebID“) erfolgt. Hierfür
              übermitteln wir personenbezogene Daten an IDnow oder WebID.
            </p>
            <p>{''}</p>
            <p>
              An IDnow oder WebID werden Stamm- und Kontaktdaten der Kunden und Vertragspartnern zum Zwecke der
              Online-Identifikation übermittelt. Die Daten werden dort für die Dauer der gesetzlichen Speicherfristen
              gespeichert. Die Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. b) und c) DS-GVO.
            </p>
            <p>{''}</p>
            <p>
              Zum Zwecke der Online-Identifikation wird zwischen IDnow oder WebID und dem jeweiligen Endgerät eine
              sichere Videoverbindung aufgebaut, da für die Identitätsbestätigung ein unmittelbarer visueller Kontakt
              notwendig ist. Im Rahmen des Online-Ident-Verfahrens überprüfen die IDnow oder WebID-Mitarbeiter
              Identitäten insbesondere anhand von Ausweisdokumenten. Von den IDnow oder WebID-Mitarbeitern müssen zum
              Nachweis der ordnungsgemäßen Durchführung der Online-Identitätsbestätigung Screenshots oder Fotos vom
              Nutzer und der vollständigen Vorder- und Rückseite der jeweiligen Dokumente angefertigt und gespeichert
              werden. Darüber hinaus wird IDnow oder WebID auch das zwischen dem Nutzer und den IDnow oder
              WebID-Mitarbeitern parallel zur Videoübertragung geführte Gespräch aufzeichnen und speichern.
            </p>
            <p>
              IDnow oder WebID haben sich von der Authentizität der vom Nutzer vorgelegten Dokumente zu überzeugen.
              Hierfür haben die IDnow oder WebID-Mitarbeiter vorschriftsgemäß visuell die Unversehrtheit und das
              Vorhandensein der optischen Sicherheitsmerkmale des jeweiligen Dokumentes zu prüfen. Bitte folgen Sie dazu
              den Anweisungen der IDnow oder WebID-Mitarbeiter, alle Dokumente vor der Kamera in bestimmte Positionen zu
              bringen. Zudem werden die IDnow oder WebID-Mitarbeiter ggf. dazu auffordern, die vollständige Nummer der
              Dokumente vorzulesen.
            </p>
            <p>{''}</p>
            <p>
              Die geltenden Datenschutzbestimmungen der IDnow können hier eingesehen werden:{' '}
              <a href="https://www.idnow.io/de/regularien/datensicherheit/">
                https://www.idnow.io/de/regularien/datensicherheit/
              </a>
            </p>
            <p>{''}</p>
            <p>
              Die geltenden Datenschutzbestimmungen der WebID Solutions finden Sie unter{' '}
              <a href="https://webid-solutions.de/datenschutzbestimmungen/">
                https://webid-solutions.de/datenschutzbestimmungen/
              </a>
            </p>
          </span>
        </DataConsentTemplate>
        {isParamsAvailable && (
          <Row className="data_consent_view_data">
            <Col className="text-left">
              <h2 className="data_consent_title text-left">Datenschutzhinweise für Kunden</h2>
            </Col>
            <Col className="data_privacy_for_customer text-left">
              <span className="data_consent_description text-left">
                <p>
                  Weitere Informationen zum Umgang mit Ihren Daten finden Sie in den{' '}
                  <span className="data_privacy_link" onClick={() => getAngebotTyp()}>
                    Datenschutzhinweisen für Kunden.
                  </span>{' '}
                  Diese sind Teil dieser Datenschutzerklärung.
                </p>
              </span>
            </Col>
          </Row>
        )}
      </Container>
    </Container>
  );
}

export default Home;
